import React, { useState } from "react";
import Header from "../Header/Header";
import heroSectionIllustration from "../../assests/images/hero-section-illustration.png";
import aboutImg from "../../assests/images/about.png";
import {
  FaCheck,
} from "react-icons/fa";
import { FaBars } from "react-icons/fa6";
import Footer from "../Footer/Footer";
import "./HomePage.css";

function HomePage() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    "Cloud Solutions",
    "Linux Solutions",
    "Infrastructure Management & Migration",
    "Backup & Disaster Recovery Solutions",
    "Centralized Identity Management",
  ];
  const tabcontent = [
    "Maximize efficiency and reduce costs with our comprehensive cloud solutions. We provide tailored cloud architectures, including cloud storage, backup, and hosting services, to help your business seamlessly leverage the power of cloud technology.",

    "Unlock the potential of open-source technology with our Linux solutions. From deployment and configuration to management and support, we cover all major distributions including Ubuntu, RedHat, CentOS, and Amazon Linux, ensuring a secure and efficient environment.",

    "Transform and modernize your IT landscape with our infrastructure management and migration services We handle the complete lifecycle of your infrastructure, ensuring smooth upgrades, migrations, and ongoing maintenance for uninterrupted business operations.",

    "Protect your data and ensure business continuity with our backup and disaster recovery solutions. We offer advanced backup strategies and rapid recovery plans, designed to minimize downtime and safeguard your critical data against any potential threats.",

    "Enhance your IT security and streamline access management with our Active Directory setup services We deliver customized AD solutions that simplify user authentication, authorization, and policy enforcement, ensuring seamless integration across your entire network.",
  ];

  return (
    <>
      <Header />
      <div className="container-fluid px-0 hero-section-container">
        {/* <img className="w-100" src={heroBg} alt="Image" />  */}

        <div className="container p-0 hero-section">
          <div className="row align-items-center  ">
            <div className="col-lg-6 hero-section-first text-lg-start text-center ">
              <p className="d-inline-block display-7  text-primary fw-semi-bold  hero-section-p pt-lg-0 pt-3  ">
                Welcome to ItechExs
              </p>
              <h1 className="display-7 mb-4 hero-section-h1 pb-lg-0 pb-3 primary-text-color  ">
                Empowering Your IT Infrastructure with Expertise and Innovation
              </h1>
            </div>
            <div className="col-lg-6  hero-section-second text-lg-end text-center">
              <img className="w-75" src={heroSectionIllustration} alt="Image" />
            </div>
          </div>
        </div>
      </div>

      {/* <!-- About Start --> */}
      <div id="about" className="container-xxl pt-5 pb-3 mt-4">
        <div className="container">
          <p className="display-6 d-inline-block primary-text-color fw-bold py-4 w-100 text-center my-4">
            About Us  
          </p>
          <div className="row g-4  mb-4  rounded box-background ">
            <div className="col-lg-6 wow fadeInUp my-3" data-wow-delay="0.1s">
              <img className="img-fluid rounded" src={aboutImg} />
            </div>
            <div
              className="col-lg-6 wow fadeInUp  p-4 content-section mt-0 "
              data-wow-delay="0.3s"
            >
              {/* <h1 className="display-5 mb-4">
                We Help Our Clients To Grow Their Business
              </h1> */}
              <p className="aboutus-content mb-4">
                At ItechExs, we are a team of dedicated IT professionals with a
                collective experience of over 10 years in delivering top-notch
                solutions across various sectors, including government projects
                and high- profile AWS deployments
              </p>
              <p className=" aboutus-content mb-4">
                We pride ourselves on our ability to offer comprehensive IT
                services that encompass both cloud and on-premise environments,
                leveraging the latest technologies in virtualization, web
                servers, and database management. Whether you're looking to.
                optimize your existing infrastructure, develop a custom web
                application, or ensure robust disaster recovery, ItechExs has
                the expertise and experience to deliver results.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Features Start --> */}
      <div class="container-xxl feature pt-3 pb-3 mb-3 feature-section">
        <div class="container">
          <div class="row g-5  flex-column align-items-center">
            <div
              class="col-12 wow fadeInUp features-section-1 p-3 pb-3 text-center"
              data-wow-delay="0.1s"
            >
              <p class=" display-6 d-inline-block  primary-text-color fw-bold mb-0  p-1">
                Why Choosing Us!
              </p>
            </div>
            <div class="col-12">
              <div class="row g-4 align-items-center">
                <div class="col-12">
                  <div class="row justify-content-center">
                    <div
                      class="col-lg-4 col-md-6 col-sm-10 wow fadeIn "
                      data-wow-delay="0.3s"
                    >
                      <div class="feature-box border rounded p-4 box-background h-100">
                        <i class="fa fa-check fa-3x primary-text-color mb-3 facheck-size">
                          <FaCheck />
                        </i>
                        <h4 class="mb-3">Fast Executions</h4>
                        <p class="mb-3">
                          Clita erat ipsum et lorem et sit, sed stet lorem sit
                          clita duo justo erat amet
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-6 col-sm-10 wow fadeIn mt-3 mt-md-0"
                      data-wow-delay="0.5s"
                    >
                      <div class="feature-box border rounded p-4 box-background h-100">
                        <i class="fa fa-check fa-3x primary-text-color mb-3 facheck-size">
                          <FaCheck />
                        </i>
                        <h4 class="mb-3">Certified Expertise</h4>
                        <p class="mb-3">
                          Our team includes AWS Solution Architects and Red Hat
                          Certified professionals, ensuring that you receive the
                          highest level of technical expertise.
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-6 col-sm-10 wow fadeIn mt-3 mt-lg-0 "
                      data-wow-delay="0.5s"
                    >
                      <div class="feature-box border rounded p-4 box-background h-100">
                        <i class="fa fa-check fa-3x primary-text-color mb-3 facheck-size">
                          <FaCheck />
                        </i>
                        <h4 class="mb-3">Proven Track Record</h4>
                        <p class="mb-3">
                          With experience in government and well-known A WS
                          projects, we bring a wealth of knowledge and a proven
                          track record to every project.
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-md-6 col-sm-10 wow fadeIn mt-3"
                      data-wow-delay="0.5s"
                    >
                      <div class="feature-box border rounded p-4 box-background h-100">
                        <i class="fa fa-check fa-3x primary-text-color mb-3 facheck-size">
                          <FaCheck />
                        </i>
                        <h4 class="mb-3">Dedicated Support</h4>
                        <p class="mb-3">
                          We provide continuous support and guidance, ensuring
                          that your IT infrastructure runs smoothly and
                          efficiently.
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-md-6 col-sm-10 wow fadeIn mt-3"
                      data-wow-delay="0.5s"
                    >
                      <div class="feature-box border rounded p-4 box-background h-100">
                        <i class="fa fa-check fa-3x primary-text-color mb-3 facheck-size">
                          <FaCheck />
                        </i>
                        <h4 class="mb-3">Cutting- -Edge Technology</h4>
                        <p class="mb-3">
                          We Stay ahead of the curve by utilizing the latest
                          technologies in virtualization, automation, and cloud
                          computing to drive innovation in your business
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Features End --> */}

      {/* <!-- Service Start --> */}
      <div id="services" class="container-xxl service py-3">
        <div class="container">
          <div
            class="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p class="display-6 d-inline-block primary-text-color fw-bold py-1 px-3 mb-5">
              Our Services
            </p>
            {/* <h1 class="display-5 mb-5">
              Awesome Financial Services For Business
            </h1> */}
          </div>
          <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
            <div class="col-lg-4">
              <div class="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                {tabs.map((tab, index) => (
                  <button
                    className={`nav-link w-100 d-flex align-items-center text-start border p-4 mb-4  ${
                      activeTab === index ? "active" : ""
                    } `}
                    data-bs-toggle="pill"
                    data-bs-target="#tab-pane-1"
                    type="button"
                    key={index}
                    onClick={() => setActiveTab(index)}
                  >
                    <h5 class="m-0 d-flex">
                      <i class="fa fa-bars primary-text-color me-3">
                        <FaBars />
                      </i>
                      <p className="m-0">{tab}</p>
                    </h5>
                  </button>
                ))}
              </div>
            </div>
            <div class="col-lg-8">
              <div class="tab-content w-100 h-100 box-background">
                <div class="tab-pane fade show active" id="tab-pane">
                  <div class="row g-4">
                    <div class="col-12">
                      <p class="mb-4">{tabcontent[activeTab]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      <Footer />
    </>
  );
}

export default HomePage;
